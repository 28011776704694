import { Injectable } from '@angular/core';
import { GetNextBusinessDayResp } from '@xpo-ltl-2.0/sdk-location';
import { InspectionContext } from '@xpo-ltl/sdk-inspections';
import { ColDef, GridOptions, ICellRendererParams, RowNode, ValueGetterParams } from 'ag-grid-community';
import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { differenceBy as _differenceBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { BroadcastMessage } from '../../classes/broadcast-messages/broadcast-message';
import { Indicator } from '../../classes/indicator';
import { PickupRequestItem } from '../../classes/pickup-request-item';
import { ComparatorUtils } from '../../classes/utils/comparator-utils';
import { InspectionsDateUtils } from '../../classes/utils/inspections-date-utils';
import { CorrectionRequestDialogComponent } from '../../components/correction-request-dialog/correction-request-dialog.component';
import { CustomerGuidelinesCellRendererComponent } from '../../components/customer-guidelines/customer-guidelines-cell-renderer/customer-guidelines-cell-renderer.component';
import { InspectionDetailDialogColumnComponent } from '../../components/inspection-detail-dialog-column/inspection-detail-dialog-column.component';
import { NmfcColumnComponent } from '../../components/list-shipments/nmfc-column/nmfc-column.component';
import {
  RouterLinkColumnComponent,
  RouterLinkParam
} from '../../components/list-shipments/router-link-column/router-link-column.component';
import { PickupRequestMarkCellRendererComponent } from '../../components/pickup-requests/cell-renderers/pickup-request-mark-cell-renderer/pickup-request-mark-cell-renderer.component';
import { toPartyRoleDisplayName } from '../../components/recommendation-rules/enums/party_role.enum';
import { IndicatorRendererComponent } from '../../components/trailer-detail/indicator-renderer/indicator-renderer.component';
import { AgGridFilterType } from '../../enums/ag-grid-filter-type';
import { ColumnType } from '../../enums/column-type.enum';
import { IndicatorName } from '../../enums/indicator-name.enum';
import { InspectionState, toInspectionStateDisplayName } from '../../enums/inspection-state.enum';
import { ListName } from '../../enums/list-name.enum';
import { RouterUriComponents } from '../../enums/router-uri-components.enum';
import { AppConstantsService } from '../app-constants.service';
import { LocationService } from '../location/location.service';
import { UserPreferencesService } from '../user-preferences.service';
import { GridSettingsData } from './grid-settings-data';
import { CorrectionReviewStatusText } from '../../enums/correction-review-status.enum';
import { GridUtils } from '../../classes/utils/grid-utils';
/*
  There are two main reasons for this Service:

    1. Saving/Loading/Resetting Grid User Preferences
      a. Column Order
      b. Column Width
      c. Column Pinning
      d. Column Visibility

    2. Storing/Restoring Grid State for the session (not resetting during navigation)
      a. Column Order
      b. Column Width
      c. Column Pinning
      d. Column Visibility
      e. Sorting
      f. Filters

  ag-grid is a little painful when it comes to saving and restoring the grid state.
  The basic idea is that anytime a column changes, we want to save the entire grid state
  by calling storeGridState, which will persist the change to a GridSettingsData object (one per ListName)

  Anytime the data (onRowDataChanged) is updated, we will re-apply/restore the Grid Settings by
  calling the restoreGridState.  We have to call it after the onRowDataChanged in order for the Filters
  to be applied properly.

  Saving and loading User preferences can now just operate on the Grid Settings Data (omitting
  the Column Definition, sorting and filtering). We will have to call the restoreGridState after loading as the load will
  only update the GridSettingsData.

  Observables didn't seem to work very well for this service.
 */

@Injectable()
export class GridSettingsService {
  static readonly MAXIMUM_ROW_NUMBER_IN_CELL = 5;
  private gridSettingsMap = new Map<ListName, GridSettingsData>();

  // Map to check to see if the list has been loaded or not... Only load once.
  private listLoadedMap: Map<ListName, boolean> = new Map<ListName, boolean>();

  private isUpdateNeededForSavedColumns: boolean = false;
  private _inspectionCorrectionList_filter_isSearchForSic: boolean;

  private listHeaderFilterStartDateSubject$ = new BehaviorSubject<Date>(undefined);
  listHeaderFilterStartDate$ = this.listHeaderFilterStartDateSubject$.asObservable();

  private listHeaderFilterEndDateSubject$ = new BehaviorSubject<Date>(undefined);
  listHeaderFilterEndDate$ = this.listHeaderFilterEndDateSubject$.asObservable();

  private autoCorrectionListFilterStartDateSubject$ = new BehaviorSubject<Date>(undefined);
  autoCorrectionListFilterStartDate$ = this.autoCorrectionListFilterStartDateSubject$.asObservable();

  private autoCorrectionListFilterEndDateSubject$ = new BehaviorSubject<Date>(undefined);
  autoCorrectionListFilterEndDate$ = this.autoCorrectionListFilterEndDateSubject$.asObservable();

  private listHeaderFilterOnlyInReviewStatusSubject$ = new BehaviorSubject<boolean>(true);
  listHeaderFilterOnlyInReviewStatus$ = this.listHeaderFilterOnlyInReviewStatusSubject$.asObservable();

  constructor(
    private constantsService: AppConstantsService,
    private userPreferencesService: UserPreferencesService,
    private locationService: LocationService
  ) {
    this.resetGridSettings(ListName.AUTO_CORRECTION_APPROVAL);
    this.resetGridSettings(ListName.RECOMMENDED);
    this.resetGridSettings(ListName.FLAGGED);
    this.resetGridSettings(ListName.INSPECTED);
    this.resetGridSettings(ListName.COMPLETED);
    this.resetGridSettings(ListName.DISMISSED);
    this.resetGridSettings(ListName.LOOKUP);
    this.resetGridSettings(ListName.ADD_PROS);
    this.resetGridSettings(ListName.RECOMMENDATION_RULES);
    this.resetGridSettings(ListName.CUSTOMER_GUIDELINES);
    this.resetGridSettings(ListName.BROADCAST_MESSAGES);
    this.resetGridSettings(ListName.PICKUP_REQUESTS);
    this.resetGridSettings(ListName.INSPECTION_CORRECTIONS);
  }

  static buildGridIcons(): any {
    return {
      checkboxIndeterminate: '<i class="material-icons" style="color: rgba(0, 0, 0, 0.54)">indeterminate_check_box</i>',
      checkboxUnchecked: '<i class="material-icons" style="color: rgba(0, 0, 0, 0.54)">check_box_outline_blank</i>',
      checkboxChecked: '<i class="material-icons" style="color: #0087ea">check_box</i>',
      groupExpanded:
        '<i class="material-icons" style="color: rgba(0, 0, 0, 0.54); margin-top: 16px;">keyboard_arrow_up</i>',
      groupContracted:
        '<i class="material-icons" style="color: rgba(0, 0, 0, 0.54); margin-top: 16px;">keyboard_arrow_down</i>'
    };
  }

  buildDefaultAddProsListColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = this.buildAddProColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  buildDefaultBroadcastMessagesListColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = this.buildBroadcastMessagesListColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  buildDefaultCompletedListColumnDefs(): ColDef[] {
    let columnDefs: ColDef[];
    if (this.locationService.isCorrectionsFeatureAvailable) {
      columnDefs = this.buildCompletedListColumnDefsWithCorrections();
    } else {
      columnDefs = this.buildCompletedListColumnDefsWithoutCorrections();
    }
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  buildDefaultCorrectionsListColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = this.buildCorrectionsListColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  buildDefaultCustomerGuidelinesListColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = this.buildDefaultCustomerGuidelinesColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  buildDefaultDismissedListColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = this.buildDefaultPlannedShipmentListColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  buildDefaultFlaggedListColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = this.buildDefaultPlannedShipmentListColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  buildDefaultInspectedListColumnDefs(): ColDef[] {
    let columnDefs: ColDef[];
    if (this.locationService.isCorrectionsFeatureAvailable) {
      columnDefs = this.buildInspectedListColumnDefsWithCorrections();
    } else {
      columnDefs = this.buildInspectedListColumnDefsWithoutCorrections();
    }
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  buildDefaultLookupListColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = this.buildLookupColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  buildDefaultPickUpRequestsListColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = this.buildDefaultPickupRequestsColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  buildDefaultAutoCorrectionsListColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = this.buildDefaultAutoCorrectionsColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  buildDefaultRecommendedListColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = this.buildDefaultPlannedShipmentListColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  buildDefaultRecommendedRulesListColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = this.buildDefaultRecommendRulesListColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  clearFilterGridSettings(listName: ListName, gridOptions: GridOptions = null) {
    const gridSettingsData = this.gridSettingsMap.get(listName);
    gridSettingsData.filterModel = null;
    if (gridOptions && gridOptions.api) {
      gridOptions.api.setFilterModel(gridSettingsData?.filterModel);
    }
  }

  clearSortGridSettings(listName: ListName, gridOptions: GridOptions = null) {
    const gridSettingsData = this.gridSettingsMap.get(listName);
    gridSettingsData.sortModel = null;
    if (gridOptions && gridOptions.api) {
      gridOptions.api.setSortModel(gridSettingsData?.sortModel);
    }
  }

  hasFilterGridSettings(listName: ListName) {
    const gridSettingsData = this.gridSettingsMap.get(listName);
    return !this.isEmpty(gridSettingsData?.filterModel);
  }

  hasSortGridSettings(listName: ListName) {
    const gridSettingsData = this.gridSettingsMap.get(listName);
    return !this.isEmpty(gridSettingsData?.sortModel);
  }

  loadListGridSettings(listName: ListName, gridOptions: GridOptions) {
    this.userPreferencesService
      .loadListUserPreferences(listName)
      .pipe(take(1))
      .subscribe((savedUserPreference: string) => {
        if (savedUserPreference) {
          const gridSettings: GridSettingsData = this.gridSettingsMap.get(listName);
          // Reset Filters and Sorts on Load
          gridSettings.filterModel = null;
          gridSettings.sortModel = null;

          // update(add/delete columns) on savedUserPreference
          const updatedColumnState: ColumnState[] = this.buildUpdatedColumnState(savedUserPreference, gridOptions);
          gridSettings.columnState = updatedColumnState;
          if (this.isUpdateNeededForSavedColumns) {
            // update user preference settings in DB so no need to update columns every time
            this.saveListGridSettings(listName);
          }

          if (gridOptions) {
            this.restoreGridState(listName, gridOptions);
          }
        }
      });
  }

  resetGridSettings(listName: ListName, gridOptions: GridOptions = null) {
    this.gridSettingsMap.set(listName, new GridSettingsData()); // just Clear the Grid Settings Data

    if (gridOptions) {
      // if the gridOptions is passed in, the update the Grid State
      this.restoreGridState(listName, gridOptions);
    }
  }

  // Restore grid state is used to re-apply the existing grid state after a data change
  // for sorts, filters, and columnState.  It will also load the users preferences if it's the
  // first time the lists are loaded for the lifetime of the application
  restoreGridState(listName: ListName, gridOptions: GridOptions) {
    const gridSettings = this.gridSettingsMap.get(listName);
    if (!this.listLoadedMap.get(listName)) {
      // LoadListGridSettings calls this method, restoreGridState, so make sure we set
      // listLoaded to true so this method doesn't get called again
      this.listLoadedMap.set(listName, true);
      this.loadListGridSettings(listName, gridOptions);
    } else {
      if (gridSettings?.columnState) {
        gridOptions.columnApi.setColumnState(gridSettings.columnState);
      } else {
        gridOptions.columnApi.resetColumnState();
      }
      gridOptions.api.setSortModel(gridSettings.sortModel);
      gridOptions.api.setFilterModel(gridSettings.filterModel);
    }
  }

  saveListGridSettings(listName: ListName) {
    // Have to create a copy first of GridSettingsData, otherwise we'll affect the existing
    // Save/Restore functionality
    // We only have to save the ColumnState
    const gridSettingsData = this.gridSettingsMap.get(listName);
    const saveDataGridSettingsData = new GridSettingsData();
    saveDataGridSettingsData.columnState = gridSettingsData?.columnState;

    const jsonString = JSON.stringify(saveDataGridSettingsData);

    this.userPreferencesService.saveListUserPreferences(listName, jsonString);
  }

  storeGridState(listName: ListName, gridOptions: GridOptions) {
    const gridSettings = this.gridSettingsMap.get(listName);
    gridSettings.columnState = gridOptions.columnApi.getColumnState();
    gridSettings.sortModel = gridOptions.api.getSortModel();
    gridSettings.filterModel = gridOptions.api.getFilterModel();
  }

  private applyCustomTypeFunctions(columnState: ColDef[]) {
    columnState.forEach((column) => {
      // Run Cell Style on all columns for New and Strikethrough.
      if (column.field !== 'guideline') {
        column.cellStyle = this.customCellStyles;
      } else {
        column.cellStyle = this.customCellStylesForCustomerGuidelines;
      }
      this.setColumnFilterIfNotAlreadySet(column);
      if (column?.refData?.columnType) {
        switch (column.refData.columnType) {
          case ColumnType.TIMESTAMP:
            column.valueFormatter = this.dateTimeValueFormatter;
            column.comparator = this.dateTimeComparator;
            break;
          case ColumnType.FAK:
          case ColumnType.NMFC:
          case ColumnType.COMMODITY_DESCRIPTION:
            column.cellRenderer = this.fullArrayCellRenderer;
            column.comparator = (a: string[], b: string[]) => {
              return ComparatorUtils.arrayOfStringAndNumberCompare(a, b);
            };
            column.filterParams = {
              cellRenderer: this.getValueForFilterCellRenderer,
              comparator: (a: string, b: string) => {
                return ComparatorUtils.stringAndNumberCompare(a, b);
              }
            };
            break;
          case ColumnType.NMFC_CLASS:
            column.type = 'rightAligned';
            column.cellRenderer = this.fullArrayCellRenderer;
            column.comparator = (a: string[], b: string[]) => {
              return ComparatorUtils.arrayOfStringAndNumberCompare(a, b);
            };
            column.filterParams = {
              cellRenderer: this.getValueForFilterCellRenderer,
              comparator: (a: string, b: string) => {
                return ComparatorUtils.stringAndNumberCompare(a, b);
              }
            };
            break;
          case ColumnType.CUSTOMER_INSTRUCTIONS:
          case ColumnType.MODELS:
            column.cellRenderer = this.arrayCellRenderer;
            column.comparator = (a: string[], b: string[]) => {
              return ComparatorUtils.arrayOfStringAndNumberCompare(a, b);
            };
            column.filterParams = {
              cellRenderer: this.getValueForFilterCellRenderer,
              comparator: (a: string, b: string) => {
                return ComparatorUtils.stringAndNumberCompare(a, b);
              }
            };
            break;
          case ColumnType.INSPECTION_STATUS:
            column.cellRenderer = this.inspectionStatusCellRenderer;
            column.filterParams = { cellRenderer: this.getValueForFilterCellRenderer };
            break;
          case ColumnType.AUTO_CORRECTION_STATUS:
            column.cellRenderer = this.autoCorrectionStatusCellRenderer;
            column.filterParams = { cellRenderer: this.getValueForFilterCellRenderer };
            break;
          case ColumnType.YES_BOOLEAN:
            column.cellRenderer = this.yesRenderer;
            column.filterParams = { cellRenderer: this.getValueForFilterCellRenderer };
            break;
          case ColumnType.PARTY_ROLE:
            column.cellRenderer = this.partyRoleCellRenderer;
            column.filterParams = { cellRenderer: this.partyRoleCellRenderer };
            break;
          case ColumnType.PAYMENT_TYPE:
            column.cellRenderer = this.titleCaseRenderer;
            column.filterParams = { cellRenderer: this.titleCaseRenderer };
            break;
          case ColumnType.NUMBER:
            column.type = 'rightAligned';
            column.cellRenderer = this.getValueForFilterCellRenderer;
            column.filter = AgGridFilterType.NUMBER;
            column.filterParams = {
              inRangeInclusive: true
            };
            break;
          case ColumnType.CURRENCY:
            column.valueFormatter = this.currencyValueFormatter;
            column.filter = AgGridFilterType.NUMBER;
            column.filterParams = {
              inRangeInclusive: true
            };
            break;

          case ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER:
            column.valueFormatter = this.valueGetter;
            column.type = 'rightAligned';
            column.filterParams = { cellRenderer: this.getValueForFilterCellRenderer };
            break;
        }
      }
    });
  }

  private arrayCellRenderer(params: ICellRendererParams): string {
    if (!params.value || !Array.isArray(params.value) || params.value.length === 0) {
      return '';
    }

    let cellValue = '';
    for (let i = 0; i < GridSettingsService.MAXIMUM_ROW_NUMBER_IN_CELL; i++) {
      if (params.value[i]) {
        cellValue += '<div style="line-height: 24px">' + params.value[i] + '</div>';
      }
    }

    return cellValue;
  }

  private buildAddProColumnDefs(): ColDef[] {
    return [
      { field: 'rank', headerName: 'Rank', width: 160, pinned: 'left' },
      { field: 'proNumber', headerName: 'PRO', width: 150 },
      {
        field: 'inspectionStatus',
        headerName: 'Inspection Status',
        refData: { columnType: ColumnType.INSPECTION_STATUS }, // Used for custom CellRenderers and Comparators
        width: 200
      },
      {
        field: 'eta',
        headerName: 'ETA',
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }, // Used for custom CellRenderers and Comparators
        width: 250
      },
      { field: 'location', headerName: 'Location', width: 200 },
      {
        field: 'breakDoor',
        headerName: 'Br Dr',
        width: 110,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        field: 'loadDoor',
        headerName: 'Ld Dr',
        width: 110,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      { field: 'shipperName', headerName: 'Shipper', width: 225 },
      { field: 'consigneeName', headerName: 'Consignee', width: 225 },
      { field: 'billTo', headerName: 'Bill-To', width: 225 },
      { field: 'originSic', headerName: 'Orig', width: 110 },
      { field: 'destSic', headerName: 'Dest', width: 110 },
      {
        field: 'totalPieceCnt',
        headerName: 'Pcs',
        width: 110,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        field: 'motorizedPieceCnt',
        headerName: 'MM',
        width: 110,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        field: 'loosePieceCnt',
        headerName: 'LP',
        width: 110,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        field: 'totalGrossWeight',
        headerName: 'Wgt',
        width: 110,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'totalGrossVolume',
        headerName: 'CuFt',
        width: 110,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'density',
        headerName: 'PCF',
        width: 110,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        headerName: 'FAK',
        autoHeight: true,
        field: 'fak',
        refData: { columnType: ColumnType.FAK }, // Used for custom CellRenderers and Comparators
        width: 200
      },
      {
        headerName: 'NMFC',
        autoHeight: true,
        field: 'nmfc',
        refData: { columnType: ColumnType.NMFC }, // Used for custom CellRenderers and Comparators
        width: 115
      },
      {
        headerName: 'Class',
        autoHeight: true,
        field: 'nmfcClass',
        refData: { columnType: ColumnType.NMFC_CLASS }, // Used for custom CellRenderers and Comparators
        width: 150
      },
      {
        headerName: 'Commodity Description',
        width: 265,
        autoHeight: true,
        field: 'commodity',
        refData: { columnType: ColumnType.COMMODITY_DESCRIPTION } // Used for custom CellRenderers and Comparators
      },
      {
        headerName: 'Cust Instr',
        autoHeight: true,
        sortable: false,
        refData: { columnType: ColumnType.CUSTOMER_INSTRUCTIONS }, // Used for custom CellRenderers and Comparators
        field: 'customerInstructions',
        width: 1000
      },
      {
        headerName: 'Transaction Date',
        field: 'transactionDate',
        suppressMenu: true,
        width: 300,
        refData: { columnType: ColumnType.TIMESTAMP }
      }
    ];
  }

  private buildBroadcastMessagesListColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = [
      {
        field: 'messageId',
        headerName: 'Message ID',
        width: 150,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      { field: 'messageType', headerName: 'Type', width: 150 },
      {
        field: 'messageStatus',
        headerName: 'Status',
        width: 150,
        valueGetter: (params: ValueGetterParams) => {
          if (!params || !params.data) {
            return '';
          }
          const broadcastMessage: BroadcastMessage = params.data;
          return broadcastMessage.getDisplayedStatus();
        }
      },
      { field: 'message', headerName: 'Message', width: 600 },
      { field: 'createdBy', headerName: 'Created By', width: 200 },
      {
        field: 'createdAt',
        headerName: 'Created Date',
        width: 200,
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }
      },
      { field: 'updatedBy', headerName: 'Updated By', width: 200 },
      {
        field: 'updatedAt',
        headerName: 'Updated Date',
        width: 200,
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }
      }
    ];

    return columnDefs;
  }

  private buildCompletedListColumnDefsWithCorrections(): ColDef[] {
    const columnDefs: ColDef[] = [
      { headerName: 'PRO', field: 'proNumber', width: 150 },
      {
        headerName: 'Inspection Status',
        field: 'inspectionStatus',
        refData: { columnType: ColumnType.INSPECTION_STATUS }, // Used for custom CellRenderers and Comparators
        width: 200
      },
      { headerName: 'Bill Status', field: 'billStatus', width: 150 },
      { headerName: 'Shipper', field: 'shipperName', width: 225 },
      { headerName: 'Consignee', field: 'consigneeName', width: 225 },
      { headerName: 'Bill-To', field: 'billTo', width: 225 },
      { headerName: 'Inspector', field: 'inspectorName', width: 250 },
      {
        headerName: 'Completion Date',
        field: 'transactionDate',
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }, // Used for custom CellRenderers and Comparators
        width: 300
      }
    ];

    return columnDefs;
  }

  private buildCompletedListColumnDefsWithoutCorrections(): ColDef[] {
    const columnDefs: ColDef[] = [
      { headerName: 'PRO', field: 'proNumber', width: 150 },
      {
        headerName: 'Inspection Status',
        field: 'inspectionStatus',
        refData: { columnType: ColumnType.INSPECTION_STATUS }, // Used for custom CellRenderers and Comparators
        width: 200
      },
      { headerName: 'Bill Status', field: 'billStatus', width: 150 },
      { headerName: 'Shipper', field: 'shipperName', width: 225 },
      { headerName: 'Consignee', field: 'consigneeName', width: 225 },
      { headerName: 'Bill-To', field: 'billTo', width: 225 },
      { headerName: 'Inspector', field: 'inspectorName', width: 250 },
      {
        headerName: 'Completion Date',
        field: 'transactionDate',
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }, // Used for custom CellRenderers and Comparators
        width: 300
      }
    ];

    return columnDefs;
  }

  private buildCorrectionsListColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = [
      { headerName: 'PRO', field: 'proNbr', width: 120 },
      {
        headerName: 'Correction ID',
        field: 'correctionId',
        width: 120,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        headerName: 'Correction Request Details',
        field: 'correctionRequestIdOrCellValue',
        width: 200,
        cellRendererFramework: CorrectionRequestDialogComponent
      },
      { headerName: 'Correction Status', field: 'correctionStatusCd', width: 240 },
      { headerName: 'Auto Correction', field: 'autoCorrectionInd', width: 160 },
      { headerName: 'Correction Type', field: 'shortDescription', width: 240 },
      {
        headerName: 'Revenue Impact',
        field: 'revenueImpact',
        width: 160,
        refData: { columnType: ColumnType.CURRENCY } // Used for custom valueFormatter
      },
      { headerName: 'Correction Request Inspector', field: 'correctionInspectorFullName', width: 240 }, // inspector who submitted the inspection
      { headerName: 'Inspection Inspector', field: 'inspectionInspectorFullName', width: 160 }, // inspection inspector
      {
        headerName: 'Inspection Details',
        field: 'inspectionId',
        width: 140,
        cellRendererFramework: InspectionDetailDialogColumnComponent
      },
      {
        headerName: 'Last Update',
        field: 'updatedTimestamp',
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }, // Used for custom CellRenderers and Comparators
        width: 200
      }
    ];

    return columnDefs;
  }

  private buildDefaultCustomerGuidelinesColumnDefs(): any {
    const columnDefs = [
      {
        field: 'customerGuidelineId',
        headerName: 'Guideline ID',
        width: 110,
        minWidth: 50,
        maxWidth: 150,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      { field: 'acctMadCd', headerName: 'MAD Code', width: 140, minWidth: 50, maxWidth: 160 },
      {
        field: 'acctName',
        headerName: 'Account',
        width: 250,
        minWidth: 50,
        maxWidth: 300
      },
      {
        field: 'guideline',
        headerName: 'Guideline',
        minWidth: 600,
        width: 600,
        cellRendererFramework: CustomerGuidelinesCellRendererComponent,
        wrapText: true,
        autoHeight: true
      },
      { field: 'createByName', headerName: 'Created By', width: 180, minWidth: 50, maxWidth: 200 },
      {
        field: 'createdTimestamp',
        headerName: 'Create Date',
        width: 180,
        minWidth: 50,
        maxWidth: 180,
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }
      },
      { field: 'updateByName', headerName: 'Updated By', width: 180, minWidth: 50, maxWidth: 200 },
      {
        field: 'updatedTimestamp',
        headerName: 'Update Date',
        width: 180,
        minWidth: 50,
        maxWidth: 180,
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }
      }
    ];

    return columnDefs;
  }

  private buildInspectedListColumnDefsWithCorrections(): ColDef[] {
    const columnDefs: ColDef[] = [
      { headerName: 'PRO', field: 'proNumber', width: 150 },
      {
        headerName: 'Inspection Status',
        field: 'inspectionStatus',
        refData: { columnType: ColumnType.INSPECTION_STATUS }, // Used for custom CellRenderers and Comparators
        width: 200
      },
      { field: 'locationWithInspectionPriority', headerName: 'Shipment Status', width: 160 },
      {
        headerName: 'Correction Request',
        field: 'correctionRequestRouterLink',
        width: 148,
        cellRendererFramework: RouterLinkColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.INSPECTION_CORRECTIONS}`
        } as RouterLinkParam
      },
      {
        headerName: 'Correction Request Status',
        field: 'correctionRequestStatus',
        refData: { columnType: ColumnType.INSPECTION_STATUS }, // Used for custom CellRenderers and Comparators
        width: 200
      },
      {
        field: 'locationPriority',
        headerName: 'Priority',
        width: 90,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      { field: 'trailerStatus', headerName: 'Trailer Status', width: 120 },
      { field: 'location', headerName: 'Location', width: 100 },
      {
        field: 'trailerNumber',
        headerName: 'Trailer Number',
        width: 128,
        cellRendererFramework: RouterLinkColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as RouterLinkParam
      },
      {
        field: 'breakDoor',
        headerName: 'Br Dr',
        width: 80,
        cellRendererFramework: RouterLinkColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as RouterLinkParam,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        field: 'loadDoor',
        headerName: 'Ld Dr',
        width: 80,
        cellRendererFramework: RouterLinkColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as RouterLinkParam,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      { headerName: 'Bill Status', field: 'billStatus', width: 150 },
      { headerName: 'Shipper', field: 'shipperName', width: 225 },
      { headerName: 'Consignee', field: 'consigneeName', width: 225 },
      { headerName: 'Bill-To', field: 'billTo', width: 225 },
      { headerName: 'Inspector', field: 'inspectorName', width: 250 },
      {
        headerName: 'Inspection Date',
        field: 'transactionDate',
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }, // Used for custom CellRenderers and Comparators
        width: 300
      }
    ];
    return columnDefs;
  }

  private buildInspectedListColumnDefsWithoutCorrections(): ColDef[] {
    const columnDefs: ColDef[] = [
      { headerName: 'PRO', field: 'proNumber', width: 150 },
      {
        headerName: 'Inspection Status',
        field: 'inspectionStatus',
        refData: { columnType: ColumnType.INSPECTION_STATUS }, // Used for custom CellRenderers and Comparators
        width: 200
      },
      { field: 'locationWithInspectionPriority', headerName: 'Shipment Status', width: 160 },
      {
        field: 'locationPriority',
        headerName: 'Priority',
        width: 90,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      { field: 'trailerStatus', headerName: 'Trailer Status', width: 120 },
      { field: 'location', headerName: 'Location', width: 100 },
      {
        field: 'trailerNumber',
        headerName: 'Trailer Number',
        width: 128,
        cellRendererFramework: RouterLinkColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as RouterLinkParam
      },
      {
        field: 'breakDoor',
        headerName: 'Br Dr',
        width: 80,
        cellRendererFramework: RouterLinkColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as RouterLinkParam,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        field: 'loadDoor',
        headerName: 'Ld Dr',
        width: 80,
        cellRendererFramework: RouterLinkColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as RouterLinkParam,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      { headerName: 'Bill Status', field: 'billStatus', width: 150 },
      { headerName: 'Shipper', field: 'shipperName', width: 225 },
      { headerName: 'Consignee', field: 'consigneeName', width: 225 },
      { headerName: 'Bill-To', field: 'billTo', width: 225 },
      { headerName: 'Inspector', field: 'inspectorName', width: 250 },
      {
        headerName: 'Inspection Date',
        field: 'transactionDate',
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }, // Used for custom CellRenderers and Comparators
        width: 300
      }
    ];
    return columnDefs;
  }

  private buildDefaultPickupRequestsColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = [
      { field: 'shipperName', headerName: 'Shipper Name', width: 200 },
      { field: 'shipperAddress', headerName: 'Shipper Address', width: 300 },
      { field: 'madCode', headerName: 'MAD Code', width: 130 },
      { field: 'proNumber', headerName: 'PRO', width: 130 },
      {
        field: 'pallets',
        headerName: 'Pallets',
        width: 130,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        field: 'pieces',
        headerName: 'Pieces',
        width: 130,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        field: 'weight',
        headerName: 'Weight',
        width: 130,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'motorMoves',
        headerName: 'MM',
        width: 130,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      { field: 'remarks', headerName: 'Remarks', width: 130 },
      {
        headerName: 'Indicators',
        width: 130,
        cellRendererFramework: PickupRequestMarkCellRendererComponent,
        valueGetter: (params: ValueGetterParams) => {
          return this.getIndicators(params);
        },
        filterValueGetter: (params: ValueGetterParams) => {
          return this.getIndicatorNames(params);
        }
      },
      {
        field: 'cube',
        headerName: 'CuFt',
        width: 130,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'density',
        headerName: 'PCF',
        width: 130,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'callNumber',
        headerName: 'Call Number',
        width: 200,
        valueGetter: (param: ValueGetterParams) => {
          const pickupRequest: PickupRequestItem = param.data;
          if (!pickupRequest) {
            return;
          }
          return pickupRequest.getFormattedCallNumber();
        },
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      { field: 'pickupStatus', headerName: 'Pickup Status', width: 150 },
      { field: 'inspectionStatus', headerName: 'Inspection Status', width: 180 },
      { field: 'destinationSicCd', headerName: 'Destination', width: 200 }
    ];
    return columnDefs;
  }

  private buildDefaultAutoCorrectionsColumnDefs(): ColDef[] {

    const columnDefs: ColDef[] = [
      GridUtils.buildProNumberColumn({ field: 'proNbr' }),
      GridUtils.buildSicColumn({ field: 'parentInspectionSicCd' }),
      { field: 'region', headerName: 'Region', width: 190},
      GridUtils.buildYesBooleanColumn({ headerName: 'Exceeds 75', field: 'isExceeds75' }),
      GridUtils.buildStatusColumn({ headerName: 'Status', field: 'reviewStatusCd' }),
      { field: 'correctionType', headerName: 'Correction Type', width: 140},
      { field: 'reviewerFullName', headerName: 'Reviewed By', width: 160},
      GridUtils.buildTimestampColumn({ headerName: 'Created Date', field: 'auditInfo.createdTimestamp' }),
      GridUtils.buildTimestampColumn({ headerName: 'Updated Date', field: 'auditInfo.updatedTimestamp' }),
    ];
    return columnDefs;
  }

  private buildDefaultPlannedShipmentListColumnDefs(): ColDef[] {
    const columnDefs = [
      {
        headerName: 'Rank',
        autoHeight: true,
        width: 76,
        field: 'modelNames',
        refData: { columnType: ColumnType.MODELS }, // Used for custom CellRenderers and Comparators
        pinned: 'left'
      },
      {
        field: 'eta',
        headerName: 'ETA',
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }, // Used for custom CellRenderers and Comparators
        width: 164
      },
      { field: 'locationWithInspectionPriority', headerName: 'Shipment Status', width: 160 },
      {
        field: 'locationPriority',
        headerName: 'Priority',
        width: 90,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      { field: 'trailerStatus', headerName: 'Trailer Status', width: 120 },
      {
        field: 'location',
        headerName: 'Location',
        width: 100,
        comparator: (a: string, b: string) => {
          return ComparatorUtils.stringAndNumberCompare(a, b);
        }
      },
      {
        field: 'trailerNumber',
        headerName: 'Trailer Number',
        width: 128,
        cellRendererFramework: RouterLinkColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as RouterLinkParam
      },
      {
        field: 'breakDoor',
        headerName: 'Br Dr',
        width: 80,
        comparator: (a: string, b: string) => {
          return ComparatorUtils.stringAndNumberCompare(a, b);
        },
        cellRendererFramework: RouterLinkColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as RouterLinkParam,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        field: 'loadDoor',
        headerName: 'Ld Dr',
        width: 80,
        comparator: (a: string, b: string) => {
          return ComparatorUtils.stringAndNumberCompare(a, b);
        },
        cellRendererFramework: RouterLinkColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as RouterLinkParam,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      { field: 'proNumber', headerName: 'PRO', width: 104 },
      { field: 'shipperName', headerName: 'Shipper', width: 220 },
      { field: 'consigneeName', headerName: 'Consignee', width: 220 },
      { field: 'billTo', headerName: 'Bill-To', width: 225 },
      { field: 'originSic', headerName: 'Orig', width: 68 },
      { field: 'destSic', headerName: 'Dest', width: 68 },
      {
        field: 'totalPieceCnt',
        headerName: 'Pcs',
        width: 68,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        field: 'motorizedPieceCnt',
        headerName: 'MM',
        width: 68,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        field: 'loosePieceCnt',
        headerName: 'LP',
        width: 68,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        field: 'totalGrossWeight',
        headerName: 'Wgt',
        width: 68,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'totalGrossVolume',
        headerName: 'CuFt',
        width: 68,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'density',
        headerName: 'PCF',
        width: 68,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        headerName: 'FAK',
        autoHeight: true,
        field: 'fak',
        refData: { columnType: ColumnType.FAK }, // Used for custom CellRenderers and Comparators
        width: 104
      },
      {
        headerName: 'NMFC',
        autoHeight: true,
        field: 'nmfc',
        width: 112,
        cellRendererFramework: NmfcColumnComponent,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        headerName: 'Class',
        autoHeight: true,
        field: 'nmfcClass',
        refData: { columnType: ColumnType.NMFC_CLASS }, // Used for custom CellRenderers and Comparators
        width: 96
      },
      {
        headerName: 'Commodity Description',
        width: 264,
        autoHeight: true,
        field: 'commodity',
        refData: { columnType: ColumnType.COMMODITY_DESCRIPTION } // Used for custom CellRenderers and Comparators
      },
      { field: 'inspectorName', headerName: 'Inspector', width: 120 },
      {
        headerName: 'Transaction Date',
        field: 'transactionDate',
        suppressMenu: true,
        width: 164,
        refData: { columnType: ColumnType.TIMESTAMP }
      }
    ];
    return columnDefs;
  }

  private buildDefaultRecommendRulesListColumnDefs(): any {
    const columnDefs = [
      {
        field: 'ruleInstId',
        headerName: 'Rule ID',
        width: 135,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      { field: 'typeCd', headerName: 'Recommend', width: 135 },
      { field: 'acctName', headerName: 'Account', width: 200 },
      { field: 'acctMadCd', headerName: 'MAD Code', width: 140 },
      {
        field: 'partyRole',
        headerName: 'Party',
        width: 110,
        refData: { columnType: ColumnType.PARTY_ROLE } // Used for custom CellRenderers and Comparators
      },
      { field: 'consignee', headerName: 'To Consignee Account', width: 200 },
      { field: 'consigneeMadCd', headerName: 'To Consignee MAD Code', width: 200 },
      {
        field: 'inspectionChargeCd',
        headerName: 'Payment Type',
        width: 145,
        refData: { columnType: ColumnType.PAYMENT_TYPE }
      },
      { field: 'createdId', headerName: 'Created By', width: 150 },
      {
        field: 'createdDate',
        headerName: 'Create Date',
        suppressMenu: true,
        width: 180,
        refData: { columnType: ColumnType.TIMESTAMP }
      },
      { field: 'lastUpdatedId', headerName: 'Updated By', width: 150 },
      {
        field: 'updatedDate',
        headerName: 'Update Date',
        suppressMenu: true,
        width: 180,
        refData: { columnType: ColumnType.TIMESTAMP }
      }
    ];

    return columnDefs;
  }

  private buildLookupColumnDefs(): ColDef[] {
    return [
      { headerName: 'PRO', field: 'proNumber', width: 104 },
      {
        headerName: 'Inspection Status',
        field: 'inspectionStatusCd',
        width: 160,
        refData: { columnType: ColumnType.INSPECTION_STATUS }
      }, // Used for custom CellRenderers and Comparators },
      { headerName: 'Shipper', field: 'shipperName', width: 240 },
      {
        headerName: 'Load Sequence #',
        field: 'trailerLoadSequenceNbr',
        width: 140,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        headerName: 'MM',
        field: 'motorizedPieceCnt',
        width: 68,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        headerName: 'Pcs',
        field: 'totalPieceCnt',
        width: 68,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      },
      {
        headerName: 'Cube',
        field: 'pupVolumePercentage',
        width: 72,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        headerName: 'Wgt',
        field: 'totalGrossWeight',
        width: 68,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        colId: 'indCol',
        headerName: 'Indicators',
        width: 150,
        filterValueGetter: (params: ValueGetterParams) => {
          return this.getIndicatorNames(params);
        },
        cellRendererFramework: IndicatorRendererComponent,
        valueGetter: (params: ValueGetterParams) => {
          return this.getIndicators(params);
        }
      },
      { headerName: 'Dest', field: 'destSic', width: 68 },
      {
        headerName: 'Ld Dr',
        field: 'loadDoor',
        width: 80,
        refData: { columnType: ColumnType.RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER } // Used for custom column type
      }
    ];
  }

  /**
   * update grid settings that are saved in DB when we(IT) added/deleted columns because DB info is not automatically updated
   *
   * @param savedGridSettingsString
   * @param gridOptions
   * @returns
   */
  private buildUpdatedColumnState(savedGridSettingsString: string, gridOptions: GridOptions): ColumnState[] {
    const savedColumnStates: ColumnState[] = JSON.parse(savedGridSettingsString).columnState;
    const latestColumnStates: ColumnState[] = gridOptions.columnApi?.getColumnState();

    if (savedColumnStates) {
      // addedColumns = latestColumnState - savedColumnState
      const addedColumns: ColumnState[] = _differenceBy(
        latestColumnStates,
        savedColumnStates,
        (colState) => colState.colId
      );
      // deletedColumns = savedColumnState - latestColumnState
      const deletedColumns: ColumnState[] = _differenceBy(
        savedColumnStates,
        latestColumnStates,
        (colState) => colState.colId
      );

      const isAddedColumnExisted: boolean = addedColumns?.length > 0;
      const isDeletedColumnExisted: boolean = deletedColumns?.length > 0;

      this.isUpdateNeededForSavedColumns = isAddedColumnExisted || isDeletedColumnExisted;

      if (this.isUpdateNeededForSavedColumns) {
        // insert newly added columns
        if (isAddedColumnExisted) {
          addedColumns.forEach((newColumn: ColumnState) => {
            const indexNbr: number = latestColumnStates.findIndex(
              (latestColumn: ColumnState) => latestColumn.colId === newColumn.colId
            );
            if (indexNbr > -1) {
              savedColumnStates.splice(indexNbr, 0, newColumn);
            }
          });
        }
        // remove deleted columns
        if (isDeletedColumnExisted) {
          deletedColumns.forEach((deletedColumn: ColumnState) => {
            const indexNbr: number = savedColumnStates.findIndex(
              (savedColumn: ColumnState) => savedColumn.colId === deletedColumn.colId
            );
            if (indexNbr) {
              savedColumnStates.splice(indexNbr, 1);
            }
          });
        }
      }

      return savedColumnStates;
    } else {
      return latestColumnStates;
    }
  }

  private customCellStyles(params: any): any {
    const cellStyle = {};
    if (params.data.removedSinceRefresh) {
      cellStyle['text-decoration'] = 'line-through';
    }

    if (params.data.addedSinceRefresh) {
      cellStyle['font-weight'] = 'bold';
    }

    if (params.data.priorityShipment) {
      cellStyle['background-color'] = '#FFE0C2';
    }

    return cellStyle;
  }

  private customCellStylesForCustomerGuidelines(params: any) {
    const cellStyle = {};

    if (params.data.guideline.includes('\n')) {
      cellStyle['white-space'] = 'break-spaces';
      cellStyle['line-height'] = 'normal';
    }

    return cellStyle;
  }

  private dateTimeComparator(valueA: any, valueB: any, nodeA?: RowNode, nodeB?: RowNode, isInverted?: boolean): number {
    let c1 = 0;
    let c2 = 0;

    if (valueA) {
      c1 = valueA;
    }
    if (valueB) {
      c2 = valueB;
    }
    return c1 - c2;
  }

  private dateTimeValueFormatter(data: any): string {
    if (!data.value) {
      return '';
    } else {
      return InspectionsDateUtils.getFormattedDateTime(data.value);
    }
  }

  private currencyValueFormatter(data: any): string {
    const num: number = data.value;

    return num.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }

  private valueGetter(data: any): string {
    return data.value;
  }

  private fullArrayCellRenderer(params: ICellRendererParams): string {
    if (!params.value || !Array.isArray(params.value) || params.value.length === 0) {
      return '';
    }

    let cellValue = '';
    params.value.forEach((value) => {
      if (value) {
        cellValue += '<div class="cellRenderer_array_with_value">' + value + '</div>';
      } else {
        cellValue += '<div class="cellRenderer_empty_array"></div>';
      }
    });
    return cellValue;
  }

  private getIndicatorNames(params: ValueGetterParams): string {
    let indicatorNames: string[] = [];

    this.getIndicators(params).forEach((indicator: Indicator) => {
      indicatorNames.push(indicator.name);
    });

    return indicatorNames.toString();
  }

  private getIndicators(params: ValueGetterParams): Indicator[] {
    const indicators: Indicator[] = [];

    if (params.data.freezableInd) {
      indicators.push(new Indicator(IndicatorName.FREEZABLE));
    }
    if (params.data.garntdInd || params.data.guaranteedInd) {
      indicators.push(new Indicator(IndicatorName.GUARANTEED));
    }
    if (params.data.hazmatInd) {
      indicators.push(new Indicator(IndicatorName.HAZMAT));
    }
    if (params.data.headloadInd) {
      indicators.push(new Indicator(IndicatorName.HEADLOAD));
    }
    if (params.data.foodInd) {
      indicators.push(new Indicator(IndicatorName.FOOD));
    }
    if (params.data.rrsInd) {
      indicators.push(new Indicator(IndicatorName.RAPID_REMOTE_SERVICE));
    }
    return indicators;
  }

  private getValueForFilterCellRenderer(params: ICellRendererParams): string {
    return params.value;
  }

  private isEmpty(obj) {
    if (!obj) {
      return true;
    }
    if (Array.isArray(obj) && obj.length === 0) {
      return true;
    }

    for (const key of Object.keys(obj)) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  private partyRoleCellRenderer(params: ICellRendererParams): string {
    if (!params.value) {
      return '';
    }
    return toPartyRoleDisplayName(params.value, true);
  }

  private inspectionStatusCellRenderer(params: ICellRendererParams): string {
    if (!params.value) {
      return '';
    }
    let cellValue = '';
    if (
      params.value === toInspectionStateDisplayName(InspectionState.IN_PROGRESS) ||
      params.value === toInspectionStateDisplayName(InspectionState.EDITING)
    ) {
      cellValue += '<div><div class="inProgressCellStyle centerCell">' + params.value + '<div></div>';
    } else {
      cellValue += '<div class="centerCell" ><div>' + params.value + '<div></div>';
    }
    return cellValue;
  }

  private autoCorrectionStatusCellRenderer(params: ICellRendererParams): string {
    if (!params.value) {
      return '';
    }

    return CorrectionReviewStatusText[params.value] ?? params.value;
  }

  private yesRenderer(params: ICellRendererParams): string {
    if (params?.value=== true){
      return 'YES'
    }
    return ""
  }

  private titleCaseRenderer(params: ICellRendererParams): string {
    if (!params.value) {
      return '';
    }
    return AppConstantsService.toTitleCase(params.value);
  }

  private setColumnFilterIfNotAlreadySet(column: ColDef) {
    if (!column.filter && column?.refData?.columnType) {
      let newFilter: AgGridFilterType | undefined;
      switch (column.refData.columnType) {
        case ColumnType.TIMESTAMP:
          newFilter = AgGridFilterType.DATE;
          break;
        case ColumnType.NUMBER:
          newFilter = AgGridFilterType.NUMBER;
          break;
      }
      if (newFilter) {
        column.filter = newFilter;
      }
    }
  }

  setDefaultFilterForInspectionCorrectionList() {
    const today: Date = new Date();
    let sicCd: string = '';
    const offsetNumber: number = 0;
    this.inspectionCorrectionList_filter_isSearchForSic = false;

    this.constantsService.inspectionContext$
      .pipe(
        filter((context: InspectionContext) => !!context),
        take(1),
        switchMap((context: InspectionContext) => {
          sicCd = context.inspectionSic;

          return this.locationService.getLastBusinessDay(sicCd, offsetNumber).pipe(take(1));
        })
      )
      .subscribe((resp: GetNextBusinessDayResp) => {
        const startDateString: string = resp?.startDate?.replace('-', '/');

        this.listHeaderFilterStartDate = new Date(startDateString);

        this.listHeaderFilterEndDate = new Date();
      });
  }

  set listHeaderFilterStartDate(startDate: Date) {
    this.listHeaderFilterStartDateSubject$.next(startDate);
  }

  set listHeaderFilterEndDate(endDate: Date) {
    this.listHeaderFilterEndDateSubject$.next(endDate);
  }

  set autoCorrectionListFilterStartDate(startDate: Date) {
    this.autoCorrectionListFilterStartDateSubject$.next(startDate);
  }

  set autoCorrectionListFilterEndDate(endDate: Date) {
    this.autoCorrectionListFilterEndDateSubject$.next(endDate);
  }

  get listHeaderFilterStartDate() {
    return this.listHeaderFilterStartDateSubject$.value;
  }

  get listHeaderFilterEndDate() {
    return this.listHeaderFilterEndDateSubject$.value;
  }

  set listHeaderFilterOnlyInReviewStatus(value: boolean) {
    this.listHeaderFilterOnlyInReviewStatusSubject$.next(value);
  }

  get listHeaderFilterOnlyInReviewStatus() {
    return this.listHeaderFilterOnlyInReviewStatusSubject$.value;
  }

  set inspectionCorrectionList_filter_isSearchForSic(isSearchForSic: boolean) {
    this._inspectionCorrectionList_filter_isSearchForSic = isSearchForSic;
  }
}
