import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { InspectionsApiCorrectionsWrapperService } from '../api/inspections-api-corrections-wrapper.service';
import { RefreshDatesService } from '../refresh-dates.service';

@Injectable({
  providedIn: 'root'
})
export class AutoCorrectionListCacheService {
  _rowData: any[];
  _outstandingCount: number = 0;
  
  private rowDataBehaviorSubject = new BehaviorSubject<any[]>([]);
  rowData$ = this.rowDataBehaviorSubject.asObservable();

  private outstandingCountBehaviorSubject = new BehaviorSubject<number>(0);
  outstandingCount$ = this.outstandingCountBehaviorSubject.asObservable();

  _settings: {startDate: Date, endDate: Date, onlyInReviewStatus: boolean};
  _regionMap: Map<string, string>;

  constructor(
    private inspectionsApiCorrectionsWrapperService: InspectionsApiCorrectionsWrapperService,
    public refreshDatesService: RefreshDatesService,
  ) {
    this._regionMap = new Map();
    this._rowData = [];
    this._outstandingCount = 0;
    this._settings = {
        startDate: new Date(),
        endDate: new Date(),
        onlyInReviewStatus: false,
      }; 
  }

  get rowData() {
    return this._rowData;
  }

  set rowData(newData) {
    if (Array.isArray(newData)) {
      this.rowDataBehaviorSubject.next(newData);
      this._rowData = newData;
    } else {
      throw new TypeError('rowData must be an array');
    }
  }

  get outStandingCount() {
    return this._outstandingCount;
  }

  set outStanding(newData) {
    this.outstandingCountBehaviorSubject.next(newData);
    this._outstandingCount = newData;
  }

  get regionMap() {
    return this._regionMap;
  }

  set regionMap(newMap) {
    if (newMap instanceof Map) {
      this._regionMap = newMap;
    } else {
      throw new TypeError('regionMap must be an instance of Map');
    }
  }

  get settings() {
    return this._settings;
  }

  set settings(newSettings) {
    if (
      typeof newSettings === 'object' &&
      newSettings !== null &&
      newSettings.startDate instanceof Date &&
      newSettings.endDate instanceof Date &&
      typeof newSettings.onlyInReviewStatus === 'boolean'
    ) {
      this._settings = newSettings;
    } else {
      throw new TypeError(
        'settings must be an object with valid startDate (Date), endDate (Date), and onlyInReviewStatus (boolean)'
      );
    }
  }

  loadOutstandingData(){
    this.inspectionsApiCorrectionsWrapperService
    .getCorrections(undefined, undefined, true).pipe(take(1)).subscribe((result) => {
        this.outStanding = result.correctionRequests.length;
    })
  }

}
