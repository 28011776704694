import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CorrectionRequest, GetInspectionShipmentDetailsResp } from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject, Subject, take, takeUntil } from 'rxjs';
import { ProNumber } from '../../../../classes/pronumber';
import { AppNavigationService } from '../../../../services/app-navigation.service';
import { RequestValidator } from '../../../../validators/request.validator';
import { ExternalAppAccessService } from '../../../../services/externalAppAccess.service';
import { DialogWrapperService } from '../../../../services/dialog-wrapper.service';
import { InspectionCorrectionsComponent } from '../../../../components/inspection-corrections/inspection-corrections.component';
import { CorrectionReviewStatusText } from '../../../../enums/correction-review-status.enum';
import { CorrectionRequestReviewStatusCd } from '@xpo-ltl/sdk-common';
import { CorrectionInformation } from '../../../../interfaces/correction-information.interface';
import { AppConstantsService } from '../../../../services/app-constants.service';
import { LocationApiWrapperService } from '../../../../services/location/locationApiWrapperService';
import { ShipmentDetailsCacheService } from '../../../../services/cache/shipment-details-cache.service';
import { ActivatedRoute} from '@angular/router';
import { PdfViewerData, PdfViewerDialogComponent } from '../../../../components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog
} from '@angular/material/legacy-dialog';
import { ShipmentDetailsComponent } from '../../../../components/shipment-details/shipment-details.component';
import { DocumentSearch, GetDocumentResp, SearchDmsDocumentResp } from '@xpo-ltl/sdk-documentmanagement';
import { XpoLtlDocumentService } from '@xpo-ltl/ngx-ltl';
import { DmsDocType } from '../../../../enums/dms-doc-type.enum';
import { DmsDocumentNameValue, DocumentUtilsService } from '../../../../services/document-utils.service';
import * as moment from 'moment';
import { DateMilitaryFormatPipe } from '../../../../pipes/date-military-format.pipe';

@Component({
  selector: 'app-correction-information',
  templateUrl: './correction-information.component.html',
  styleUrls: ['./correction-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorrectionInformationComponent implements OnChanges {
  @Input() correctionRequest: CorrectionRequest;
  @Input() correctionInformation: CorrectionInformation;
  @Input() is75: boolean;
  @Input() shipmentDetailsResp: GetInspectionShipmentDetailsResp;
  @Input() useShipmentDetailsCache: boolean;
  @Output() reviewCommentsChanged = new EventEmitter<string>();
  @Output() closeDialog = new EventEmitter<boolean>();

  isReviewCommentsFieldDisabled$ = new BehaviorSubject<boolean>(true);
  proNbr$ = new BehaviorSubject<ProNumber>(null);
  submitCorrectionBySubject = new BehaviorSubject<string>('');

  private readonly PRO_NUMBER: string = 'PRO number';
  readonly CorrectionReviewStatusText = CorrectionReviewStatusText;
  readonly docClassOrder = [
    DmsDocType.BILL_OF_LANDING.toString(),
    DmsDocType.BILL_OF_LANDING_ATTACHMENT.toString(),
    DmsDocType.INSPECTION_CERTIFICATE.toString(),
    DmsDocType.REWEIGH_CERTIFICATE.toString(),
    DmsDocType.CUSTOMS.toString()];

  isDocumentsLoaded: boolean = false;
  documentSearchResults: DocumentSearch [];
  pdfDocuments: DmsDocumentNameValue []= [];
  documentsDialogRef;
  reviewComments: string;
  region: string;
  isExpanded = true;
  isDeclined = false;
  volume = 0;
  effectiveVolume = 0;
  showProgressSpinner = true;
  isRulesetAvailable: boolean = false;
  appliedRulesetUrl: string;
  finalDeliveryDate: string;

  get proNbr(): ProNumber {
    return this.proNbr$.value;
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public activatedRoute: ActivatedRoute,
    private appNavigation: AppNavigationService,
    private externalAppAccessService: ExternalAppAccessService,
    private dialogWrapperService: DialogWrapperService,
    private shipmentDetailsCacheService: ShipmentDetailsCacheService,
    public xpoDialog: DialogWrapperService,
    public appConstantsService: AppConstantsService,
    private locationApiWrapperService: LocationApiWrapperService,
    public dialogRef: MatDialogRef<PdfViewerDialogComponent>,
    private matDialog: MatDialog,
    private documentService: XpoLtlDocumentService,
    private documentUtilsService: DocumentUtilsService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['correctionRequest']) {
      this.locationApiWrapperService.listOperationalRegions().pipe(take(1)).subscribe((responseMap: Map<string, string>) => {
        this.region = responseMap.get(this.correctionRequest?.requestorSicCd);
        this.reviewComments = this.correctionRequest?.reviewComment;
        this.isReviewCommentsFieldDisabled$.next(this.correctionRequest?.reviewStatusCd !== CorrectionRequestReviewStatusCd.IN_REVIEW);
        this.proNbr$.next(new ProNumber(this.correctionRequest?.proNbr));
        this.isDeclined = this.correctionRequest?.reviewStatusCd === CorrectionRequestReviewStatusCd.DECLINED;
        const cachedShipmentDetailsPro = new ProNumber(this.shipmentDetailsCacheService?.shipmentDetails?.shipmentDetails?.shipmentId?.proNumber);
        if (this.useShipmentDetailsCache && cachedShipmentDetailsPro?.toString() === this.proNbr.toString()){
          this.isDocumentsLoaded = true;
          this.pdfDocuments = this.shipmentDetailsCacheService?.pdfDocuments;
        } else {
          this.loadPdfDocuments(this.correctionRequest?.proNbr);
        }
      })
    }
    if(changes['correctionInformation']) {
     this.calculateTotalVolume();
    }
    if (changes['shipmentDetailsResp']) {
      if (this.shipmentDetailsResp) {
        this.showProgressSpinner = false;
        const proNumber = new ProNumber(this.shipmentDetailsResp?.shipmentDetails?.shipmentId?.proNumber).formatProNumber();
        const pickupDate = this.shipmentDetailsResp?.shipmentDetails?.shipmentId?.pickupDate;
        const appliedRulesetNbr = this.shipmentDetailsResp?.shipmentDetails?.appliedRulesetNbr;
        this.populateFinalDeliveryDates(this.shipmentDetailsResp.shipmentDetails.destSic, this.shipmentDetailsResp?.shipmentDetails.finalDeliveryDateTime);
        const appliedAgreementId = this.shipmentDetailsResp?.shipmentDetails?.appliedAgreementId;
        if (proNumber && pickupDate && appliedRulesetNbr && appliedAgreementId) {
          this.isRulesetAvailable = true;
          const queryParams = [];
          queryParams.push('proId=' + proNumber);
          queryParams.push('shipmentPkupDt=' + pickupDate);
          queryParams.push('rsSeq=' + appliedRulesetNbr);
          queryParams.push('docId=' + appliedAgreementId);
          queryParams.push('popUp=Y');
          this.appliedRulesetUrl = this.appConstantsService.getPricingAppEndpoint(['initRulesetView.do'], queryParams);
        }
      }
    }
  }

  populateFinalDeliveryDates(destSic: string, finalDeliveryDateTime: Date) {
    const dateMilitaryFormatPipe: DateMilitaryFormatPipe = new DateMilitaryFormatPipe();
    this.finalDeliveryDate = dateMilitaryFormatPipe.transform(+finalDeliveryDateTime);
    if (this.finalDeliveryDate === ""){
      this.finalDeliveryDate = "NA"
    } else {
      const finalDeliveryDate = moment(finalDeliveryDateTime).format('YYYY-MM-DD');
      this.locationApiWrapperService.getNextBusinessDay(destSic, finalDeliveryDate, 1).pipe(take(1)).subscribe((result) => {
        this.submitCorrectionBySubject.next(moment(result.nextBusinessDay).format("MM/DD/YY"));
      });
    }
  }

  public calculateTotalVolume() {
    let totalVolume = 0;
    let totalEffectiveVolume = 0;
    this.correctionInformation?.dimensionerInspectionResp?.dimensionerInspection?.dimensionerInspectionDetail.forEach((dimensionerInspectionDetail) => {
      totalVolume = totalVolume + dimensionerInspectionDetail?.volumeCubicFeet;
      if (dimensionerInspectionDetail?.effectiveVolumeCubicFeet){
        totalEffectiveVolume = totalEffectiveVolume + dimensionerInspectionDetail?.effectiveVolumeCubicFeet;
      } else {
        totalEffectiveVolume = totalEffectiveVolume + dimensionerInspectionDetail?.volumeCubicFeet;
      }
    });
    this.volume = totalVolume;
    this.effectiveVolume = totalEffectiveVolume;
  }

  onProLinkClicked(mouseEvent: MouseEvent) {
    this.shipmentDetailsCacheService.shipmentDetails = this.shipmentDetailsResp;
    mouseEvent.preventDefault();
    this.appNavigation.navigateToShipmentDetailsWithQueryParams(this.proNbr, true, this.correctionRequest?.correctionRequestId.toString());
    this.closeDialog.emit(true);
  }

  onOpenCorrectionsApp(mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();

    const formattedProNumberString = this.proNbr.formatProNumber();
    RequestValidator.validateStringNotNullOrEmpty(formattedProNumberString, this.PRO_NUMBER);
    const shipmentManagementAppBaseUrl: string = this.externalAppAccessService.buildCorrectionsAppUrl(
      formattedProNumberString
    );

    window.open(shipmentManagementAppBaseUrl);
  }

  loadPdfDocuments(proNumber) {
    this.documentService
      .listAvailableDocuments(proNumber)
      .pipe(take(1))
      .subscribe(
        (searchDmsDocumentResp: SearchDmsDocumentResp) => {
          let filteredDocuments: DocumentSearch [] = [];
          filteredDocuments = searchDmsDocumentResp?.documentInfo.filter((document) => {
            if (document?.cdt?.docClass){
              return ShipmentDetailsComponent.DOCUMENT_DOC_TYPES.includes(document?.cdt?.docClass as any);
            }
            return false;
          });
          if (filteredDocuments?.length > 0) {
            this.fetchPdfs(filteredDocuments);
          }
        },
      );
  }

  fetchPdfs(documentSearchResults: DocumentSearch []){
    this.documentSearchResults = documentSearchResults;
    const pdfsLength = documentSearchResults.length;
    documentSearchResults.forEach((pdf) => {
      if (pdf?.cdt) {
        this.documentService
          .getDocument(pdf.cdt?.timestamp, pdf.cdt?.docClass, pdf.cdt?.corpCode)
          .pipe(take(1))
          .subscribe(
            (result: GetDocumentResp) => {
              this.pdfDocuments.push({
                name: result.fileName.substring(0, result.fileName.length - 4), // ignore extension
                value: result?.documentData,
            });
            if (this.pdfDocuments.length >= pdfsLength) {
              this.pdfDocuments = this.documentUtilsService.sortDocuments(this.pdfDocuments, this.documentSearchResults, this.docClassOrder);
              this.shipmentDetailsCacheService.pdfDocuments = this.pdfDocuments;
              this.isDocumentsLoaded = true;
            }
        });
      };
    });
  }

  openDocuments(mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();
    if (this.documentsDialogRef) {
      // there's an existing one open.
      this.documentsDialogRef.close();
    }

    const dialogClosedSubject = new Subject<void>();

    const pdfViewerData: PdfViewerData = { 
      pro: this.correctionRequest.proNbr, 
      documentTypes: ShipmentDetailsComponent.DOCUMENT_DOC_TYPES,
      preloadedDocuments: this.isDocumentsLoaded ? this.pdfDocuments: undefined, 
      docClassOrder: this.docClassOrder}

    this.documentsDialogRef = this.matDialog.open(PdfViewerDialogComponent, {
      position: {
        top: '100px',
        left: '100px',
      },
      height: '880px',
      maxHeight: '1200px',

      hasBackdrop: false,
      data: pdfViewerData,
      panelClass: 'pdf-mat-dialog-panel',
    });

    this.documentsDialogRef
      .keydownEvents()
      .pipe(takeUntil(dialogClosedSubject))
      .subscribe((e: KeyboardEvent) => {
        if (e.code === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          this.documentsDialogRef.close();
        }
      });

    this.documentsDialogRef.afterClosed().subscribe(() => {
      dialogClosedSubject.next();
      dialogClosedSubject.complete();
    });
  }

  onOpenRulesets(mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();
    if (this.appliedRulesetUrl) {
      this.xpoDialog.openExternalWindow(this.appliedRulesetUrl, 'PricingRuleSet');
    }
  }

  onOpenCorrectionRequestDialog(mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();
    this.dialogWrapperService.alert(
      {
        titleText: `Details for Correction Request ID: ${this.correctionRequest?.correctionRequestId}`,
        contentComponent: InspectionCorrectionsComponent,
        hideDismissButton: true,
        injectedData: {id: +this.correctionRequest?.correctionRequestId, readOnly: true}
      },
      {
        disableClose: false,
        minWidth: '95vw'
      }
    );
  }

  public openNmftaSite(nmfc: string, mouseEvent: MouseEvent): void {
    mouseEvent.preventDefault();
    const nmftaSearchUrl = this.appConstantsService.getNmftaSearchUrl(nmfc);
    this.xpoDialog.openExternalWindow(nmftaSearchUrl, 'NMFTA Search');
  }
}
