import { Injectable } from '@angular/core';
import { DocumentSearch } from '@xpo-ltl/sdk-documentmanagement';
import { get as _get } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { decode } from 'typescript-base64-arraybuffer';

export interface DmsDocumentNameValue { name: string; value: string; }

@Injectable()
export class DocumentUtilsService {
  /**
   * Merge multiple PDF documents into one.
   *
   * @param string Encoded byte data of a pdf file
   */
  async mergePdfs(documentData: string[]): Promise<Uint8Array> {
    if (documentData?.length === 0) {
      return undefined;
    }
    const mergedPdf = await PDFDocument.create();
    for (const document of documentData) {
      const pdfBytes = decode(document);
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    }
    const mergedPdfFile = await mergedPdf.save();
    return mergedPdfFile;
  }

  sortDocuments(documents: DmsDocumentNameValue[], documentSearchResults: DocumentSearch[], docClassOrder: string[]): DmsDocumentNameValue[]{
    if (documents?.length < 2){
      return documents;
    }
    const updatedDocuments = documents.map(document => {
      const matchingSearchResult = documentSearchResults.filter((documentSearchResult) => {
        return documentSearchResult?.cdt?.timestamp === document?.name;
      });
      return {
        ...document, // Spread existing properties
        docClass: matchingSearchResult[0]?.cdt?.docClass, 
      }
    });
    updatedDocuments.sort((a, b) => {
      if (docClassOrder && a.docClass !== b.docClass) {
        return docClassOrder.indexOf(a.docClass) - docClassOrder.indexOf(b.docClass); // Primary sorting by doc type
      }
      return parseInt(a.name) - parseInt(b.name) // Secondary sort by timestamp (name is timestamp)
    });

    return updatedDocuments.map(({ docClass, ...rest }) => rest);;
  }

}
