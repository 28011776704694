import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserProfileGuard } from '../../guards/user-profile-guard';
import { MaterialModule } from '../../material.module';
import { XpoPipesModule } from '../../pipes/xpo-pipes.module';
import { CustomerInstructionsComponent } from '../customer-instructions/customer-instructions.component';
import { DocumentListModule } from '../document-list/document-list.module';
import { InspectionCorrectionsCardComponent } from '../inspection-corrections-card/inspection-corrections-card.component';
import { PhotoGalleryModule } from '../photo-gallery/photo-gallery.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { BillDetailsComponent } from './components/bill-details/bill-details.component';
import { DimensionsComponent } from './components/dimensions/dimensions.component';
import { LocationMovementComponent } from './components/location-movement/location-movement.component';
import { MeasurementsComponent } from './components/measurements/measurements.component';
import { OverviewComponent } from './components/overview/overview.component';
import { PreviousInspectionComponent } from '../previous-inspection/previous-inspection.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { ShipmentDetailsToolbarComponent } from './components/shipment-details-toolbar/shipment-details-toolbar.component';
import { ShipmentDetailsRoutingModule } from './shipment-details-routing.module';
import { ShipmentDetailsComponent } from './shipment-details.component';

@NgModule({
  imports: [
    MaterialModule,
    ShipmentDetailsRoutingModule,
    XpoPipesModule,
    ToolbarModule,
    PhotoGalleryModule,
    DocumentListModule,
    MatTooltipModule,
    CustomerInstructionsComponent,
    InspectionCorrectionsCardComponent,
    PreviousInspectionComponent,
  ],
  declarations: [
    ShipmentDetailsComponent,
    OverviewComponent,
    MeasurementsComponent,
    BillDetailsComponent,
    PricingComponent,
    LocationMovementComponent,
    DimensionsComponent,
    ShipmentDetailsToolbarComponent,
  ],
  providers: [UserProfileGuard]
})
export class ShipmentDetailsModule {}
