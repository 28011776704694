import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { combineLatest, filter, Subject, take} from 'rxjs';
import { ListName } from '../../enums/list-name.enum';
import { AppConstantsService } from '../../services/app-constants.service';
import { GridSettingsService } from '../../services/grid-settings/grid-settings.service';

@Component({
  selector: 'app-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ListHeaderComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'listHeader';

  @Input()
  title: ListName;
  @Input()
  isAddEnabled: boolean;
  @Input()
  isDateRangeDisplayed: boolean;
  @Input()
  addingObjectName: string; // we can't use title because some lists don't have add feature. if addingObjectName is undefined, no add feature.
  @Input()
  refreshTime: string;

  @Output()
  refreshTriggered = new EventEmitter<boolean>();
  @Output()
  addButtonClicked = new EventEmitter<boolean>();
  @Output()
  gridFilterCleared = new EventEmitter<boolean>();
  @Output()
  gridSortCleared = new EventEmitter<boolean>();
  @Output()
  gridSettingsSaved = new EventEmitter<boolean>();
  @Output()
  gridSettingsLoaded = new EventEmitter<boolean>();
  @Output()
  gridSettingsReset = new EventEmitter<boolean>();
  @Output()
  formGroupValueChanged = new EventEmitter<{}>();

  static readonly SEARCH_FOR_SIC_FORM_CONTROL = 'searchForSicFormControl';
  static readonly IN_REVIEW_STATUS_TOGGLE_FORM_CONTROL = 'inReviewStatusToggleFormControl';
  static readonly START_DATE_FORM_CONTROL = 'startDateFormControl';
  static readonly END_DATE_FORM_CONTROL = 'endDateFormControl';

  columnsSorted: boolean;
  columnsFiltered: boolean;
  listName = ListName;
  sic: string;
  listHeaderFormGroup: UntypedFormGroup;

  private _startDate: Date;
  private _endDate: Date;
  private _isSearchForSic: boolean;
  private _isOnlyInReviewStatus = true;

  private unsubscriber = new Subject<void>();

  constructor(
    private gridSettingsService: GridSettingsService,
    protected changeDetector: ChangeDetectorRef,
    private appConstantsService: AppConstantsService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.columnsFiltered = this.gridSettingsService.hasFilterGridSettings(this.title);
    this.columnsSorted = this.gridSettingsService.hasSortGridSettings(this.title);
    this.sic = this.appConstantsService.sicCode;
    this._isSearchForSic = this.gridSettingsService.inspectionCorrectionList_filter_isSearchForSic;
    this._isOnlyInReviewStatus = this.gridSettingsService.listHeaderFilterOnlyInReviewStatus;

    this.loadGridSettingsRangeDates();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  get isSearchForSic(): boolean {
    return this._isSearchForSic;
  }
  
  get isOnlyInReviewStatus(): boolean {
    return this._isOnlyInReviewStatus;
  }

  get startDate(): Date {
    return this._startDate;
  }

  get endDate(): Date {
    return this._endDate;
  }

  refreshList() {
    this.refreshTriggered.emit(true);
  }

  openAddObject() {
    this.addButtonClicked.emit(true);
  }

  clearGridFilters() {
    this.gridFilterCleared.emit(true);
  }

  clearGridSorts() {
    this.gridSortCleared.emit(true);
  }

  clearGridSortAndFilters() {
    this.gridFilterCleared.emit(true);
    this.gridSortCleared.emit(true);
  }

  saveGridSettings() {
    this.gridSettingsSaved.emit(true);
  }

  loadGridSettings() {
    this.gridSettingsLoaded.emit(true);
  }

  resetGridSettings() {
    this.gridSettingsReset.emit(true);
  }

  gridSettingsChanged() {
    this.columnsFiltered = this.gridSettingsService.hasFilterGridSettings(this.title);
    this.columnsSorted = this.gridSettingsService.hasSortGridSettings(this.title);
    this.changeDetector.detectChanges();
  }

  onFormGroupValueChanged() {
    if (!this.listHeaderFormGroup.get(ListHeaderComponent.END_DATE_FORM_CONTROL).value) {
      // if end date is empty, set it to today
      this._endDate = new Date();
      this.listHeaderFormGroup.get(ListHeaderComponent.END_DATE_FORM_CONTROL).setValue(this.endDate);
    } else {
      this._endDate = this.listHeaderFormGroup.get(ListHeaderComponent.END_DATE_FORM_CONTROL).value;
    }
    this._startDate = this.listHeaderFormGroup.get(ListHeaderComponent.START_DATE_FORM_CONTROL).value;
    this._isSearchForSic = this.listHeaderFormGroup.get(ListHeaderComponent.SEARCH_FOR_SIC_FORM_CONTROL).value;
    this._isOnlyInReviewStatus = this.listHeaderFormGroup.get(ListHeaderComponent.IN_REVIEW_STATUS_TOGGLE_FORM_CONTROL).value;

    this.gridSettingsService.listHeaderFilterEndDate = this.endDate;
    this.gridSettingsService.listHeaderFilterStartDate = this.startDate;
    this.gridSettingsService.inspectionCorrectionList_filter_isSearchForSic = this.isSearchForSic;
    this.gridSettingsService.listHeaderFilterOnlyInReviewStatus = this.isOnlyInReviewStatus;

    this.emitFormGroupValue();
  }
  
  private loadGridSettingsRangeDates() {
    combineLatest([
      this.gridSettingsService.listHeaderFilterStartDate$,
      this.gridSettingsService.listHeaderFilterEndDate$
    ])
      .pipe(
        filter(([startDate, endDate]: [Date, Date]) => !!startDate && !!endDate),
        take(1)
      ).subscribe(([startDate, endDate]: [Date, Date]) => {
        this._startDate = startDate;
        this._endDate = endDate;
        this.initDateRangeFormElement();
      });
  }
  
  initDateRangeFormElement() {
    this.initFormGroup();
    this.emitFormGroupValue();
  }

  private initFormGroup() {
    this.listHeaderFormGroup = this.formBuilder.group({
      [ListHeaderComponent.IN_REVIEW_STATUS_TOGGLE_FORM_CONTROL]: this.formBuilder.control(this.isOnlyInReviewStatus),
      [ListHeaderComponent.SEARCH_FOR_SIC_FORM_CONTROL]: this.formBuilder.control(this.isSearchForSic),
      [ListHeaderComponent.START_DATE_FORM_CONTROL]: this.formBuilder.control(this.startDate),
      [ListHeaderComponent.END_DATE_FORM_CONTROL]: this.formBuilder.control(this.endDate)
    });
  }

  private emitFormGroupValue() {
    this.formGroupValueChanged.emit({
      isSearchForSic: this.isSearchForSic,
      isOnlyInReviewStatus: this.isOnlyInReviewStatus,
      startDate: this.startDate,
      endDate: this.endDate
    });
  }
}
