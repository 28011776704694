import { Injectable } from '@angular/core';
import {
  DocumentManagementApiService,
  DocumentSearch,
  GetArchivedDocumentPath,
  GetArchivedDocumentQuery,
  GetShipmentImagedDocumentsQuery,
  GetShipmentImagedDocumentsResp,
  GetShipmentImagedDocumentsRqst,
  RetrieveDmsAuthTokenResp,
  SearchDmsDocumentResp,
  SearchDmsDocumentRqst
} from '@xpo-ltl/sdk-documentmanagement';
import {
  CreateArchiveDmsDocumentResp,
  DocumentInfo,
  InspectionContext,
  InspectionHandlingUnit
} from '@xpo-ltl/sdk-inspections';
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Photo } from '../../classes/photos/photo';
import { PhotoId } from '../../classes/photos/photo-id';
import { ProNumber } from '../../classes/pronumber';
import { DmsDocType } from '../../enums/dms-doc-type.enum';
import { RetryStrategyService } from '../../operators/retry-strategy.service';
import { RequestValidator } from '../../validators/request.validator';
import { AppConstantsService } from '../app-constants.service';
import { InspectionsApiWrapperService } from '../inspections/inspections-api-wrapper.service';
// import { InspectionShipmentDetailsService } from './inspection-shipment-details.service';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ApiError } from '../../classes/error/api/api-error';
import { InspectionError } from '../../classes/error/inspection-error';
import { FileContents } from '@xpo-ltl/sdk-common';

@Injectable({
  providedIn: 'root'
})
export class DocumentManagementService {
  private static DEFAULT_GET_RETRY_COUNT = 4;
  private static DEFAULT_GET_TIMEOUT = 15_000;
  private static DEFAULT_ERROR_RETRY_DELAY = 1_000; // retry every second on (non-timeout) errors
  private readonly ERROR_GETTING_PHOTO: string = 'Error Getting Photo!';
  private readonly IMAGE_FORMAT: string = 'JPEG';
  private readonly MULTI_PART_RESP: string = 'false';
  private dmsToken: string;
  private dmsTokenExpiration: number;

  constructor(
    private documentManagementApiService: DocumentManagementApiService,
    private inspectionsService: InspectionsApiWrapperService,
    private appConstantsService: AppConstantsService,
    private retryStrategyService: RetryStrategyService,
    private configManagerService: ConfigManagerService
  ) {}

  /**
   * save Inspections Photos
   * we're calling Inspections API to post photos since calling DMS directly from UI fails.
   * failing response - 415 Unsupported Media Type.
   * asked DMS team, but no response so we'll continue calling DMS from our backend side.
   *
   * @param photo
   */
  createArchiveDmsDocumentWithInspectionApi(photo: Photo): Observable<DocumentInfo> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(photo, 'Photo');

    return this.appConstantsService.inspectionContext$.pipe(
      take(1),
      switchMap((context: InspectionContext) => {
        RequestValidator.validateStringNotNullOrEmpty(context.inspectionSic, 'Inspection SIC');

        return this.inspectionsService.createArchiveDmsDocument(photo, this.appConstantsService.sicCode).pipe(
          take(1),
          map((response: CreateArchiveDmsDocumentResp) => {
            if (response?.documentInfo?.documentUrl?.trim().length > 0) {
              return response.documentInfo;
            } else {
              throw new ApiError(`Create Archive DMS Document API returned: ${response}`);
            }
          })
        );
      })
    );
  }

  listDocumentIds(parentPro: ProNumber, handlingUnits: InspectionHandlingUnit[] | undefined): Observable<PhotoId[]> {
    RequestValidator.validateProNumber(parentPro);
    //todo this is assuming the last call was dont with that ProNumber, it should be at least passed as param
    //     'this.inspectionShipmentDetailsService.getHandlinUnitsOrUndefined()'
    return this.getDmsAuth().pipe(
      take(1),
      switchMap((token: string) => {
        return this.searchDmsDocument(token, parentPro, undefined, handlingUnits);
      })
    );
  }

  /**
   * this will be called by parent PRO first, and then if there are child PROs that have dimensioner photos,
   * we'll call this recursively with the child PROs and save the parent PRO
   * @param dmsToken
   * @param proNumber
   * @param parentProNbr
   * @param inspectionHandlingUnits can set childPro for photoIds if given to define childPros
   */
  searchDmsDocument(
    dmsToken: string,
    proNumber: ProNumber,
    parentProNbr?: ProNumber,
    inspectionHandlingUnits?: InspectionHandlingUnit[]
  ): Observable<PhotoId[]> {
    RequestValidator.validateProNumber(proNumber);

    const searchRequest: SearchDmsDocumentRqst = new SearchDmsDocumentRqst();
    searchRequest.searchString = proNumber.getRawProNumber();
    searchRequest.corpCode = this.configManagerService.getSetting(ConfigManagerProperties.DMS_CORP_CODE);
    if (searchRequest.corpCode?.trim().length <= 0) {
      throw Error('Invalid corp code from settings');
    }
    searchRequest.dmsAuth = dmsToken;
    // dmsAuth must be manually added to the header
    // Client SDK doesn't quite handle it properly at this time
    return this.documentManagementApiService
      .searchDmsDocument(searchRequest, { loadingOverlayEnabled: false }, { headers: { dmsAuth: dmsToken } })
      .pipe(
        this.retryStrategyService.retryStrategy(
          DocumentManagementService.DEFAULT_GET_TIMEOUT,
          DocumentManagementService.DEFAULT_ERROR_RETRY_DELAY,
          DocumentManagementService.DEFAULT_GET_RETRY_COUNT,
          null,
          'SEARCH-DMS-DOCUMENT'
        ),
        take(1),
        switchMap((searchResponse: SearchDmsDocumentResp) => {
          const photoIds: PhotoId[] = new Array<PhotoId>();
          const documentInfoArray: DocumentSearch[] = searchResponse?.documentInfo;
          if (documentInfoArray?.length > 0) {
            // create PhotoIds based on response
            documentInfoArray.forEach((documentInfo) => {
              photoIds.push(new PhotoId(proNumber, documentInfo.cdt.docClass, documentInfo.cdt.timestamp));
            });

            if (proNumber.isChildPro(proNumber.getRawProNumber())) {
              // if proNumber is a child PRO, save parent PRO and return
              RequestValidator.validateProNumber(parentProNbr);

              photoIds.forEach((photoId: PhotoId) => {
                photoId.parentProNumber = parentProNbr;
              });
              return of(photoIds);
            } else if (proNumber.isParentPro(proNumber.getRawProNumber())) {
              // if proNumber is a parent PRO and any child PRO has dimensioner photos,
              // call searchDmsDocument recursively with child PROs
              if (
                photoIds.some((photoId: PhotoId) => photoId.docClass === DmsDocType.PICKUP_DIMENSIONER_PHOTO) &&
                inspectionHandlingUnits?.length > 0
              ) {
                return forkJoin(
                  inspectionHandlingUnits.map((inspectionHandlingUnit: InspectionHandlingUnit) => {
                    const childPro: ProNumber = new ProNumber(inspectionHandlingUnit.handlingUnitProNbr);
                    // if call searchDmsDocument with third parameter, the returned PhotoId will have
                    // childPro as proNumber and proNumber as parentProNumber because on photo gallery, we display child pro as its pro
                    return this.searchDmsDocument(dmsToken, childPro, proNumber);
                  })
                ).pipe(
                  map((resultIds: PhotoId[][]) => {
                    // flattening the response from PhotoId[][] to PhotoId[]
                    const photoIdsFromChildPro: PhotoId[] = ([] as PhotoId[]).concat(...resultIds);

                    photoIdsFromChildPro.forEach((photoIdFromChildPro: PhotoId) => {
                      const indexNbr: number = photoIds.findIndex(
                        (photoIdFromParentPro: PhotoId) => photoIdFromParentPro.id === photoIdFromChildPro.id
                      );
                      // replace photoIds with ones that have PLT info
                      if (indexNbr > -1) {
                        photoIds.splice(indexNbr, 1, photoIdFromChildPro);
                      }
                    });
                    return photoIds;
                  })
                );
              } else {
                return of(photoIds);
              }
            } else {
              return of(photoIds);
            }
          } else {
            return of(photoIds);
          }
        })
      );
  }

  /**
   * get Documents/Photo
   * getShipmentImagedDocuments is the only endpoint that can handle all the file types(JPEG, PDF, TIFF, PNG)
   * getArchiveDocument and getDocument have limitation see more info in LEI-1407
   *
   * @param photoId
   */
  getShipmentImagedDocuments(photoId: PhotoId, isLoadingOverlayEnabled: boolean): Observable<Photo> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(photoId, 'Photo ID');

    return this.getDmsAuth().pipe(
      take(1),
      switchMap((token: string) => {
        const request = new GetShipmentImagedDocumentsRqst();
        // if parent PRO exists, that means its a Child PRO. we need to request with parent PRO.
        if (photoId.parentProNumber) {
          request.proNbr = photoId.parentProNumber.formatProNumber();
        } else {
          request.proNbr = photoId.proNumber.formatProNumber();
        }
        request.dmsAuth = token;
        const query = new GetShipmentImagedDocumentsQuery();
        query.imageFormat = this.IMAGE_FORMAT;
        query.multiPartResp = this.MULTI_PART_RESP;
        query.imageType = [photoId.docClass];
        query.docArchiveTimestamp = photoId.id;

        return this.handleGetShipmentImagedDocuments(
          this.documentManagementApiService.getShipmentImagedDocuments(request, query, {
            loadingOverlayEnabled: isLoadingOverlayEnabled
          }),
          photoId
        );
      })
    );
  }

  /**
   * get DMS token when its first time calling or expired.
   */
  private getDmsAuth(): Observable<string> {
    if (this.dmsTokenExpiration > Math.ceil(moment().valueOf() / 100)) {
      return of(this.dmsToken);
    } else {
      return this.documentManagementApiService.retrieveDmsAuthToken({ loadingOverlayEnabled: false }).pipe(
        this.retryStrategyService.retryStrategy(
          DocumentManagementService.DEFAULT_GET_TIMEOUT,
          DocumentManagementService.DEFAULT_ERROR_RETRY_DELAY,
          DocumentManagementService.DEFAULT_GET_RETRY_COUNT,
          null,
          'GET_DMS_AUTH_TOKEN'
        ),
        take(1),
        map((token: RetrieveDmsAuthTokenResp) => {
          if (token?.access_token) {
            this.dmsToken = token.access_token;
            this.dmsTokenExpiration = Math.ceil(moment().valueOf() / 100) + token.expires_in;
            return token.access_token;
          } else {
            throw new ApiError(`Retrieve DMS auth token returned: ${token}`);
          }
        })
      );
    }
  }

  private getParsedImageDocumentsResponse(images: any, aPhotoId: PhotoId): Photo[] {
    const photos = new Array<Photo>();

    if (!images) {
      return photos;
    }

    const data = atob(images['binary']);
    if (!data) {
      return photos;
    }

    // parse the first line for the boundary
    // we just want the JSON from the multi part. Based on the query.multiPartResp = 'false', there should only be one JSON object
    const leftBracketIndex = data.indexOf('{');
    const rightBracketIndex = data.lastIndexOf('}') + 1;
    if (leftBracketIndex >= 0 && rightBracketIndex > leftBracketIndex) {
      const json = data.substring(leftBracketIndex, rightBracketIndex + 1);
      const photoObjects = JSON.parse(json);

      const photoData = photoObjects['data'];
      const imagedDocuments = <Array<any>>photoData['imagedDocument'];
      imagedDocuments.forEach((imagedDocument) => {
        const imageFiles = <Array<any>>imagedDocument['imageFiles'];
        imageFiles.forEach((imageFile) => {
          const photo = new Photo(aPhotoId);
          photo.fileName = imageFile['fileName'];
          photo.setBase64dataAndContentType(imageFile['base64Data'], imageFile['contentType']);
          photos.push(photo);
        });
      });
      return photos;
    } else {
      return photos;
    }
  }

  private handleGetShipmentImagedDocuments(observable: Observable<any>, photoId: PhotoId): Observable<Photo> {
    return observable.pipe(
      take(1),
      map((resp: GetShipmentImagedDocumentsResp) => {
        const photos: Photo[] = this.getParsedImageDocumentsResponse(resp, photoId); // unfortunately, dmsDocTimestamp is not part of the response
        if (photos?.length > 0) {
          const photo = photos[0];
          if (photo?.isLoaded && photo.getBase64data(false)?.trim()?.length > 0) {
            return photo;
          } else {
            throw new InspectionError(this.ERROR_GETTING_PHOTO);
          }
        } else {
          throw new InspectionError(this.ERROR_GETTING_PHOTO);
        }
      })
    );
  }

  getArchivedDdfoDocument(dimensionDocId: string): Observable<FileContents> {
    const reqQuery: GetArchivedDocumentQuery = new GetArchivedDocumentQuery();
    const pathParams: GetArchivedDocumentPath = new GetArchivedDocumentPath();
    pathParams.docClass = DmsDocType.DOCK_DIMENSIONER_FOTO;
    pathParams.corpCode = this.appConstantsService.getImageCorpCode();
    pathParams.docArchiveTimestamp = dimensionDocId; 

    return this.documentManagementApiService.getArchivedDocument(pathParams, reqQuery).pipe(take(1));
  }
}
