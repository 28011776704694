import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { XpoLtlFeedbackService } from '@xpo-ltl/ngx-ltl';
import { XpoAccountPopoverConfig } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { FeedbackConfigInterface, XpoFeedback } from '@xpo-ltl/ngx-ltl-core/feedback';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core/shell';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { LoadingOverlayService } from './components/loading-overlay/service/loading-overlay.service';
import { AccountUrls } from './enums/account-urls.enum';
import { DocumentTypeIconName } from './enums/document-type-icon-name';
import { IndicatorName } from './enums/indicator-name.enum';
import { RouteLabels, RouterUriComponents } from './enums/router-uri-components.enum';
import { AppConfigManagerService } from './services/app-config-manager.service';
import { AppConstantsService } from './services/app-constants.service';
import { AppRouteHistoryService } from './services/app-route-history.service';
import { AutoCorrectionListCacheService } from './services/cache/auto-correction-list-cache.service';
import { DialogWrapperService } from './services/dialog-wrapper.service';
import { MobileWebBrowserService } from './services/hardware/mobile-web-browser-service';
import { InspectionNotificationService } from './services/inspection-notification.service';
import { LocationService } from './services/location/location.service';
import { PhotoUploadService } from './services/photos/photo-upload.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  autoCorrectionCount: number;
  build: string;
  accountPopoverConfig: XpoAccountPopoverConfig;
  isAuthorizedUser: boolean;
  releaseNotesLink: string;
  readonly AccountUrls = AccountUrls;
  routeLabels =  RouteLabels;

  private unsubscriber$: Subject<void> = new Subject();

  // we're using routes$ for testing so we can switch Manager ver and Inspector ver.
  private routesSubject = new BehaviorSubject<XpoShellRoute[]>([]);
  routes$ = this.routesSubject.asObservable();

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public appConstantsService: AppConstantsService,
    public mobileWebBrowserService: MobileWebBrowserService,
    public userService: UserService,
    private appConfigManagerService: AppConfigManagerService,
    private dialogManager: DialogWrapperService,
    private routeHistoryService: AppRouteHistoryService,
    private dialog: MatDialog,
    private feedbackService: XpoLtlFeedbackService,
    private inspectionNotificationService: InspectionNotificationService,
    private titleService: Title,
    private locationService: LocationService,
    public loadingOverlayService: LoadingOverlayService,
    private autoCorrectionListCacheService: AutoCorrectionListCacheService,
    // ******************** Important!!! Need to bring this in to start the Photo Upload Service.
    private photoUpload: PhotoUploadService // <---- Don't remove
  ) {
    InspectionNotificationService.appInstance = this.inspectionNotificationService;
    this.iconsInit();

    this.build = this.appConfigManagerService.getBuildVersion();
    this.releaseNotesLink = this.appConfigManagerService.getReleaseNotesLink();
  }

  ngOnInit() {
    //todo might find a better way to set all pages: https://sreyaj.dev/dynamically-update-title-and-meta-tags-angular
    this.titleService.setTitle(AppConstantsService.PAGE_TITLE_PREFIX);
    this.userService.isUserLoggedIn$
      .pipe(
        filter((isUserLoggedIn: boolean) => isUserLoggedIn === true),
        takeUntil(this.unsubscriber$)
      )
      .subscribe((isUserLoggedIn: boolean) => {
        this.handleLoggedInUser();
      });
    this.routeHistoryService.listenRoutingEvt();
    this.loadGridCounts();
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  loadGridCounts() {
    this.autoCorrectionListCacheService.loadOutstandingData();
    this.autoCorrectionListCacheService.outstandingCount$.subscribe((count) => {
      this.autoCorrectionCount = count;
    })
  }

  onFeedbackClick() {
    // we need to use this feedback service instead of calling window.location.href and open email window because tablets don't support Outlook
    this.feedbackService.feedbackConfig$.pipe(take(1)).subscribe((config: FeedbackConfigInterface) => {
      this.dialog.open(XpoFeedback, { data: config });
    });
  }

  private handleLoggedInUser() {
    this.isAuthorizedUser = this.userService.isCurrentUserAuthorizedForInspectionsApp();
    if (this.isAuthorizedUser) {
      this.setAccountPopover();
      this.setRoutes();
      this.setDynatraceUserIdentity();
    } else {
      this.userService.logNotAuthorizedUser();
      this.dialogManager.showNotAuthorizedDialog();
    }
  }

  private setAccountPopover() {
    this.accountPopoverConfig = this.userService.setAccountPopover();
  }

  private setDynatraceUserIdentity() {
    this.userService.setDynatraceUserIdentity();
  }

  private setRoutes() {
    combineLatest([this.locationService.isCorrectionsFeatureAvailable$, this.userService.userRoleChanged$])
      .pipe(
        filter(
          ([isCorrectionsFeatureAvailable, isRoleChanged]: [boolean, boolean]) =>
            isCorrectionsFeatureAvailable !== undefined
        ),
        takeUntil(this.unsubscriber$)
      )
      .subscribe(([isCorrectionsFeatureAvailable, isRoleChanged]: [boolean, boolean]) => {
        if (isRoleChanged) {
          const routes: XpoShellRoute[] = [];
          for (const labelKey of Object.keys(RouteLabels)) {

            const isAutoCorrectionApproval = labelKey === this.getLabelKey(RouteLabels.AUTO_CORRECTION_APPROVAL);
            const isBroadcastMessages = labelKey === this.getLabelKey(RouteLabels.BROADCAST_MESSAGES);
            const isListInspectionCorrections = labelKey === this.getLabelKey(RouteLabels.LIST_INSPECTION_CORRECTIONS);

            // AUTO_CORRECTION_APPROVAL tab condition
            if (isAutoCorrectionApproval && !this.userService.isCurrentUserAutoCorrectionReviewer) {
              continue;
            }

            // BROADCAST_MESSAGES tab condition
            if (isBroadcastMessages && !this.userService.isCurrentUserManager) {
              continue;
            }

            // LIST_INSPECTION_CORRECTIONS tab condition
            if (isListInspectionCorrections && !isCorrectionsFeatureAvailable) {
              continue;
            }

            // Add the route if it passes all conditions
            routes.push(this.buildRoute(labelKey));
          }
          this.routesSubject.next(routes);
        }
      });
  }

  private getLabelKey(label: RouteLabels): string {
    return Object.keys(RouteLabels)[Object.values(RouteLabels).indexOf(label)];
  }

  private buildRoute(labelName: string): XpoShellRoute {
    return {
      label: RouteLabels[labelName],
      path: `/${RouterUriComponents[labelName]}`,
      queryParamsHandling: 'merge'
    };
  }

  private iconsInit() {
    this.iconRegistry.addSvgIcon(
      'xpo-logo',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/xpo_logo.svg')
    );
    this.iconRegistry.addSvgIcon(
      'gear-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/gear_icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'refresh-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_control-refresh-2x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'back-button-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_control-back-2x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'item-680-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icon-xpo-680.svg')
    );
    this.iconRegistry.addSvgIcon(
      'action-button-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_control-action-2x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'trash-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_control-trash-2x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'photo-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/ic_photo_camera_24px.svg')
    );
    this.iconRegistry.addSvgIcon(
      'back-arrow-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_back-arrow.svg')
    );
    this.iconRegistry.addSvgIcon(
      'forward-arrow-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_forward-arrow.svg')
    );
    this.iconRegistry.addSvgIcon(
      IndicatorName.ACCURACY,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_accuracy.svg')
    );
    this.iconRegistry.addSvgIcon(
      IndicatorName.FOOD,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_food.svg')
    );
    this.iconRegistry.addSvgIcon(
      IndicatorName.FREEZABLE,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_freezable.svg')
    );
    this.iconRegistry.addSvgIcon(
      IndicatorName.GUARANTEED,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_guaranteed.svg')
    );
    this.iconRegistry.addSvgIcon(
      IndicatorName.HAZMAT,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_hazmat.svg')
    );
    this.iconRegistry.addSvgIcon(
      IndicatorName.HEADLOAD,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_headload.svg')
    );
    this.iconRegistry.addSvgIcon(
      IndicatorName.POISON,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_poison.svg')
    );
    this.addDmsTypeIconsToRegistry();
    this.iconRegistry.addSvgIcon(
      'yellow-clock',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-clock-yellow-1x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'upload-clock',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-clock-1x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'upload-cloud',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-cloud-1x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'filter',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_filter.svg')
    );
    this.iconRegistry.addSvgIcon(
      'torch-on',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/cam-light-on-tablet.svg')
    );
    this.iconRegistry.addSvgIcon(
      'torch-off',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/cam-light-off-tablet.svg')
    );
  }

  private addDmsTypeIconsToRegistry() {
    this.iconRegistry.addSvgIcon(
      DocumentTypeIconName.BLAT,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-blat-3x.svg')
    );
    this.iconRegistry.addSvgIcon(
      DocumentTypeIconName.BL,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-bol-3x.svg')
    );
    this.iconRegistry.addSvgIcon(
      DocumentTypeIconName.CUST,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-customs-3x.svg')
    );
    this.iconRegistry.addSvgIcon(
      DocumentTypeIconName.WRFO,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-inspphotos-3x.svg')
    );
    this.iconRegistry.addSvgIcon(
      DocumentTypeIconName.NCIC,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-nmfcinspcert-3x.svg')
    );
    this.iconRegistry.addSvgIcon(
      DocumentTypeIconName.WI,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-wghtcorrcert-3x.svg')
    );
    this.iconRegistry.addSvgIcon(
      DocumentTypeIconName.PDFO,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-pickup_dimensioner.svg')
    );
    this.iconRegistry.addSvgIcon(
      DocumentTypeIconName.DDFO,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-cubiscan_dimensioner.svg')
    );
    this.iconRegistry.addSvgIcon(
      DocumentTypeIconName.UNKNOWN,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-unknown.svg')
    );
    this.iconRegistry.addSvgIcon(
      DocumentTypeIconName.BLANK,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-blank.svg')
    );
  }
}
